import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Button, ButtonGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";

export default function LeftSlideToggleIcon (props) {
    const {
        icon,
        label,
        onReset,
        showResetButton,
        slideInContent,
        variant,
        outline,
        disabled,
        className,
        onClick
    } = props;
    const dispatch = useDispatch();
    const setSlideInContent = () => {
        dispatch({type:'SHOW_LEFT_SLIDE', payload: true})
        dispatch({type:'LEFT_SLIDE_CONTENT', payload: slideInContent});
    }

    return(
        <ButtonGroup className={"col-auto "+className}>
        <Button
            variant={'outline'+(variant?"-"+variant:"-primary")}
            className={"pointer secondary-font " + (outline?"":"border-0")}
            disabled={disabled}
            onClick={async()=>{
                await setSlideInContent()
                await onClick&&onClick()
            }}
        >
            <p className="m-0 p-0 col-auto mx-auto row align-items-center justify-content-center">
                {icon&&<FontAwesomeIcon
                    className="col p-0 me-2"
                    icon={icon}
                />}
                {label}
            </p>
        </Button>
        {showResetButton&&
            <Button
                variant='secondary'
                onClick={onReset}
            >
                <FontAwesomeIcon 
                    icon={faTimes}
                />
            </Button>}
        </ButtonGroup>
    )
}