import React from 'react';
import { localDeployment } from '../API/config';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { Nav, Navbar, Image, Offcanvas, Dropdown, Button, Container, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt, faTimes, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { isMobile, isTablet } from 'react-device-detect';
import NotificationBubble from './notificationBubble';
import './components.css';

function Globalnavigation (){
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [expandNav, setExpandNav] = useState(false)
    const myInfo = useSelector(state=>state.myInfo)
    const portal = useSelector(state=>state.portal)
    const screen = useSelector(state=>state.screen)
    const cart = useSelector(state=>state.cart)
    const role = useSelector(state=>state.role)

    const UserDropdown = () => {
        const dropdownNavigation = [
            {
                title: portal=='EpenApp'?'EpenStore':'EpenApp',
                href: portal!=='EpenApp'?'/epenApp/'+(myInfo?'resources':''):'/epenStore',
                restrictions: localDeployment,
                withDivider:true,
                type: 'text'
            },
            {
                title: 'Account',
                href: '/epenApp/setting',
                setScreen:'controlpanel/setting',
                type: 'text'
            },
            {
                title: 'Invitation',
                href: '/epenApp/invitation',
                restrictions: role==="admin",
                setScreen:'controlpanel/invitation',
                type: 'text'
            },
            {
                title: 'Change password',
                href: '/epenApp/password',
                restrictions:false,
                withDivider:true,
                setScreen:'controlpanel/password',
                type: 'text'
            },
        ]
        return (
            myInfo?
            <Dropdown 
                className="icon-none rounded mx-2"
            >
                <Dropdown.Toggle 
                    variant="link" 
                    className="border-0 lighter-hover py-2 d-flex align-items-center"
                    style={{backgroundColor: 'rgba(255,255,255,0.2)'}}
                >
                    {!isMobile&&<p className='pe-2 m-0'>{myInfo.fullname}</p>}
                    <FontAwesomeIcon
                        icon={faUserAlt}
                    />
                </Dropdown.Toggle>
                <Dropdown.Menu
                    align={"end"}
                    className='shadow'
                    style={{zIndex: 1040}}
                >
                    {isMobile&&
                        <div>
                            <p className='ms-3 mb-0'><b>Full name: </b><br/>{myInfo.fullname}</p>
                            <NavDropdown.Divider />
                        </div>
                    }
                    {dropdownNavigation.map((item, index)=>
                        !item.restrictions&&
                        <React.Fragment key={index}>
                        <Dropdown.Item 
                            className={screen===item.setScreen?"text-secondary":"text-primary"}
                            disabled={screen===item.setScreen}
                            onClick={async ()=>{
                                await dispatch({type: "SET_PORTAL", payload: portal!=='EpenApp'?'EpenApp':'EpenStore'})
                                await item.setScreen&&dispatch({type: "SET_SCREEN", payload:item.setScreen})
                                await navigate(item.href)
                            }}
                        >{item.title}</Dropdown.Item>
                        {item.withDivider&&<NavDropdown.Divider />}
                        </React.Fragment>
                    )}
                    <Dropdown.Item className="text-danger" onClick={()=>{
                        setExpandNav(false)
                        Logout()
                    }}>Logout</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            :
            <Nav.Item>
                <Button 
                    variant='primary'
                    className='bg-none border-0 prime-font'
                    onClick={()=>{
                        navigate('./epenApp/')
                        dispatch({type:'SET_SCREEN', payload:'login'})
                        setExpandNav(false)
                    }}
                >
                {isMobile?
                <FontAwesomeIcon
                    icon={faSignInAlt}
                />
                :
                'Login'
                }
                </Button>
            </Nav.Item>
        )}


    const Logout = async () => {
        if(portal==='EpenApp'){
            await dispatch({type:'SET_SCREEN', payload:'login'})
            await navigate('./epenApp', {replace: true})
        }
        await dispatch({ type: 'LOG_OUT' })
    }
    const navigations = (!localDeployment||myInfo.admin==='1')?
    portal==='EpenApp'? 
    [
        {
            title: 'Resources',
            restrictions: false,
            type: 'text'
        },
        {
            title: 'Groups',
            restrictions: !(role==="admin"&&!localDeployment),
            type: 'text'
        },
        {
            title: 'Users',
            restrictions: !(role==="admin"&&!localDeployment),
            type: 'text'
        }
    ]
    :
    [
        {
            title: 'Home',
            restrictions: false,
            type: 'text'
        },
        {
            title: 'EpenStore',
            restrictions: false,
            type: 'text'
        },
        {
            title: 'Cart',
            restrictions: false,
            type: 'withCount'
        },
        {
            title: 'About',
            restrictions: false,
            type: 'text'
        },
    ]
    :
    []
    return(
        <Navbar 
            bg="304d73"
            expand="xl"
            variant="dark"
            className={((portal!=="EpenApp"||screen.indexOf('password')>=0||screen.indexOf('invitation')>=0)&&'drop-shadow')+' p-0 fixed-top'}
            style={{height: 67, bottom:0}}
            expanded={expandNav}
        >
        <Container>
            <Navbar.Brand 
                className='col-xl-auto col order-2 d-flex align-items-end justify-content-center pointer p-0'
            >
                <div
                    onClick={()=>{
                        navigate(localDeployment?'/epenApp'+(myInfo&&'/resources'):'/')
                        !localDeployment&&dispatch({type:"SET_PORTAL", action:'EpenStore'})
                    }}
                >
                    <Image src="/assets/logo-white.png" style={{width: 87}}/>
                </div>
                <p className='mb-0 ms-3 col text-start hide-vertical' style={{fontSize:'11pt'}}>Personalized paperless workbook</p>
            </Navbar.Brand>
            {isMobile&&
                <div className='col-auto order-3'>
                    <UserDropdown/>
                </div>
            }
            <Navbar.Toggle className='col-auto order-1 me-4' aria-controls="responsive-nav" onClick={()=>setExpandNav(true)} />
            <Navbar.Offcanvas 
                id="responsive-nav" 
                className='bg-304d73 order-3 col'
                placement="start"
            >
            <Offcanvas.Header className='w-100 pb-0'>
                {isTablet&&<Button className='ms-auto' variant="null" onClick={()=>setExpandNav(false)}>
                    <FontAwesomeIcon icon={faTimes} className="link-light"/>
                </Button>}
            </Offcanvas.Header>
            <Offcanvas.Body>
            <Nav className='justify-content-end flex-grow-1'>
                {navigations.filter(navigation=>!navigation.restrictions).map((navigation, index)=>
                    <Nav.Item key={index} className='d-flex align-items-center'>
                    <Button 
                        className={'bg-none border-0 rounded-0 '+(screen.indexOf(navigation.title.toLowerCase())>=0&&'border-bottom')}
                        disabled={screen.indexOf(navigation.title.toLowerCase())>=0}
                        onClick={()=>{
                            navigate('./'+(portal==='EpenApp'?'epenApp/':'')+(navigation.title!=='Home'?navigation.title.toLowerCase():''))
                            setExpandNav(false)
                        }}
                    >
                    {{
                    'text': navigation.title,
                    'withCount':
                    <div className='d-flex'>
                        {navigation.title}
                        {cart.length>0&&<NotificationBubble
                            variant='light'
                            className='text-dark'
                            style={{bottom:0, right:0, width:24, height:24}}
                        >{cart.length}
                        </NotificationBubble>}
                    </div>
                    }[navigation.type]}
                    </Button>
                    </Nav.Item>
                )}
                {!isMobile&&
                    <UserDropdown/>
                }
            </Nav>
            </Offcanvas.Body>
            </Navbar.Offcanvas>
        </Container>
        {(isMobile&&portal!=="EpenApp")&&
            <p
                className='mb-0 bg-white w-100 py-1 hide-horizontal position-fixed drop-shadow' 
                style={{fontSize:'11pt', zIndex:1000, top:67}}
            >Personalized paperless workbooks</p>
        }
        </Navbar>

    );
}

export default Globalnavigation;