import { Button } from "react-bootstrap"
import {  useSelector } from "react-redux";
import { useDispatch } from "react-redux";


export default function NewObjectModel (props) {
    const {
        model,
        submit,
        itemType,
        children,
    } = props
    const dispatch= useDispatch();
    const submitData = {
        "resource":useSelector(state=>state.currentResource),
        "group":useSelector(state=>state.tgtGroup),
        "user":useSelector(state=>state.tgtUser)
    }[model]
    const allowSubmit = useSelector(state=>state.allowSubmit)

    return(
        <>
            <div 
                className="text-white text-start py-3 bg-secondary col order-1"
            >
                <p className="m-0">{
                    itemType?
                    'Insert new '+model+' into '+itemType
                    :
                    'New '+model.charAt(0).toUpperCase() + model.substr(1).toLowerCase()
                }</p>

            </div>
            <div className="col-12 order-3">
                {children}
                <div className="mt-2 d-flex justify-content-end">
                    <Button
                        disabled={!allowSubmit}
                        onClick={()=>{
                            submit(submitData)
                            dispatch({ type: 'SHOW_LEFT_SLIDE', payload: false })
                        }}
                    >
                        {itemType?'Insert':'Submit'}
                    </Button>
                </div>
            </div>
        </>
    )
}