import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/Table";
import BillingsAPI from "../../../../API/payments";
import RowContainer from "../../../components/Table/rowContainer";
import PaymentAPI from "../../../../API/payments";


export default function Subscription () {
    const token = useSelector(state=>state.token)
    const dispatch = useDispatch();
    const tableEntryTemplates =
    [
        {
            label:"",
            key:'',
            col:'auto',
        },
        {
            label:"Book Title",
            key:'Title',
            aggregrated: false,
            col:'',
        },
        {
            label:"Subject",
            key:'Subject',
            aggregrated: false,
            col:'2',
        },
        {
            label:"Grade",
            key:'Level',
            aggregrated: false,
            col:'',
        },
        {
            label:"Plan",
            key:'plan',
            aggregrated: true,
            col:'3',
        },
        {
            label: 'Actions',
            key: 'actions',
            col:'2',
            aggregrated: true,
            actions:[
                {
                name:'Unsubscribe',
                verb:'DELETE',
                icon: faTrash,
                execute: async (data)=>{
                    await PaymentAPI.cancelSubscripiton(token, data._id)
                    .then(async () =>
                        await dispatch({ type: 'SET_NOTIFICATION', payload: {
                            show: true,
                            code: 200,
                            message: "Account "+data.username+' updated.'
                        }})
                    )
                }},
            ]

        },
    ]


return(
    <Table
        className="drop-shadow w-100 h-100"
        tableEntryTemplates={tableEntryTemplates}
    >
            <RowContainer
                target={'resource'}
                tableEntryTemplates={tableEntryTemplates}
                extractFilter={(e)=>e.filter(item=>item.name==='resources')[0].value}
                onLoad={(setEntries, filters)=>BillingsAPI.bllingsIndex(token, filters, 1).then(res=>
                    setEntries(
                        [...res.data.month.map(item=>
                            Object.fromEntries(new Map(
                                [...tableEntryTemplates.filter((entry, index)=>
                                    (index!==0&&!entry.aggregrated)).map((entry)=>
                                        [entry.key, item[entry.key]]
                                ),
                                ['plan', (item.priceMonthly+'/month')],
                                ]
                            ))
                        ),
                        ...res.data.year.map(item=>
                            Object.fromEntries(new Map(
                                [...tableEntryTemplates.filter((entry, index)=>
                                    (index!==0&&!entry.aggregrated)).map((entry)=>
                                        [entry.key, item[entry.key]]
                                ),
                                ['plan', (item.priceYearly+'/year')],
                                ]
                            ))
                        ),]
                    )
                )}
            />
    </Table>
)
}