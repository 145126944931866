import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
export default function LeftSlideIn ({SlideInContent}) {
    const screen = useSelector(state=>state.screen)
    
    const dispatch = useDispatch();
    const closeSlide = () => {
        dispatch({ type: 'SHOW_LEFT_SLIDE', payload: false })
    }
return(
    <div 
        className="position-fixed shadow-overlay row p-0"
        style={{zIndex:'10000'}}
    >   
        <div className={"bg-white h-100 p-0 col"}>
            <div className="bg-white h-100 overflow-y-scroll">
                <div className="row">
                {screen.indexOf('resourceIndexBySection')<0&&
                <div className="bg-secondary col-auto order-2 d-flex">
                    <Button
                        className="border-0 bg-none"
                        onClick={()=>
                            closeSlide()
                        }
                        >
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                </div>
                }
                {SlideInContent&&<SlideInContent/>}
                </div>
                <div style={{height:screen.indexOf('resourceIndexBySection')<0?0:'48%'}}>

                </div>
            </div>
        </div>
        <div
            className={(screen.indexOf('resourceIndexBySection')<0?"col-xl-7 col-2":"col-8")+" bg-none h-100"}
            onClick={()=>closeSlide()}
        >
        </div>
    </div>
    )
}