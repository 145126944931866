import { useEffect, useState } from "react";
import { useDispatch, useSelector} from "react-redux";

import LC, {LiterallyCanvasReactComponent} from "literallycanvas";
import { InputGroup, Form } from "react-bootstrap";
import { assetsSrc, socketApiKey, websocket } from "../../../../API/config"

import UploadsAPI from "../../../../API/uploads";
import DrawingAPI from "../../../../API/drawing";
import RightSlide from "../../../components/rightSlide";
import { isMobile } from "react-device-detect";


export default function EditTool (props) {
    const { 
        socket,
        setSocket,
        allowSolutions,
        setAllowSolutions,
        showSolutionsSlide,
    } = props

    const [imgs, setImgs] = useState([]);
    const [img, setImg] = useState([]);
    const [backgroundWorksheet, generateBackgroundWorksheet] = useState([]);
    const [drawingId, setDrawingId] = useState(null);
    const [drawingSnapshot, setDrawingSnapshot] = useState(null)
    const [showBanner, setShowBanner] = useState(false);
    const dispatch = useDispatch();

    const token = useSelector(state=>state.token)
    const myInfo = useSelector(state=>state.myInfo)
    const tgtUser = useSelector(state=>state.tgtUser)
    const currentResource = useSelector(state=>state.currentResource)
    const bookmark = useSelector(state=>state.bookmark)
    const srcGroup = useSelector(state=>state.srcGroup)

    let nIntervId;
    const save = async (snapshot) => {
        clearInterval(nIntervId);
        nIntervId = setInterval(async()=>{
            await DrawingAPI.updateDrawing(token, currentResource._id, tgtUser._id??myInfo._id, drawingId, {
                snapshot: snapshot.drawing.shapes.length>0?LC.renderSnapshotToImage(snapshot.drawing).toDataURL('image/png'):[],
                offset: snapshot.offset
            })
            .catch((err)=>console.log(err))
            await clearInterval(nIntervId);
            nIntervId = null
        }
        , 3000)
    }
    const LcContainer = () => {
    return(
    <>
        <LiterallyCanvasReactComponent
            onInit={canvasInit}
            defaultStrokeWidth={2}
            backgroundShapes={backgroundWorksheet}
            primaryColor={
                tgtUser._id?"#c22929":"#006eff"
            }
            tools={[
                LC.tools.Pencil,
                LC.tools.Eraser,
                // LC.tools.Line,
                // LC.tools.Rectangle,
                LC.tools.Text,
                LC.tools.Pan,
    
            ]}
        />
        <div className="position-absolute shadow"
            style={{right:"20px", bottom:"20px", width:170}}
        >
            <InputGroup className="d-flex">
                <InputGroup.Text>Page</InputGroup.Text>
                <Form.Control 
                    className="border-0 hide-arrow"
                    id="page-jump"
                    defaultValue="1"
                    type="number"
                    onClick={(e)=>
                        e.target.select()
                    }
                />
                <InputGroup.Text>/{img.length}</InputGroup.Text>
            </InputGroup>
        </div>
    </>
    )}
    useEffect(()=>{
        drawingId?
        dispatch({type:'SHOW_PRELOAD', payload: false})
        :
        dispatch({type:'SHOW_PRELOAD', payload: true});
    },[drawingId])
    const canvasInit = async (lc) => {
        if(drawingSnapshot){
            var drawingImg = new Image();
            drawingImg.src = drawingSnapshot.snapshot
            // drawingSnapshot&&lc.loadSnapshot(drawingSnapshot)
            lc.saveShape(LC.createShape('Image', {
                x: drawingSnapshot.offset?drawingSnapshot.offset[0]:0, 
                y: drawingSnapshot.offset?drawingSnapshot.offset[1]:0, 
                image: drawingImg,
                scale: 1
            }))
        }
        lc.on('pan', (data)=>{
            var page = Math.ceil(-(data.y/750/(isMobile?2:1)))+1
            document.getElementById('page-jump').value = (data.y<0?(page<img.length?page:img.length):1);
        })
        document.getElementById('page-jump').addEventListener("input", ()=>{
            var inputdata = document.getElementById('page-jump').value
            var page = (!inputdata||inputdata<1)?1:(inputdata>=img.length?img.length:inputdata)
            lc.setPan(0, (-page+1)*750*(isMobile?2:1))
        })
        //Overrriding default LC tools graphics with FA
        var tools = document.getElementsByClassName("toolbar-button thin-button")
        for ( var i=0; i<tools.length; i++) {
            var faIconChild = document.createElement('i')
            if([...tools][i].title==="Text"){
                faIconChild.className = "fa-solid fa-keyboard"
            }else if ([...tools][i].title==="Pan") {
                faIconChild.className = "fa-solid fa-up-down-left-right"
            }else if ([...tools][i].title==="Zoom out") {
                faIconChild.className = "fa-solid fa-magnifying-glass-minus"
            }else if ([...tools][i].title==="Zoom in") {
                faIconChild.className = "fa-solid fa-magnifying-glass-plus"
            }else{
                faIconChild.className = "fa-solid fa-"+ [...tools][i].title.toLowerCase()
            }
            tools[i].style.backgroundImage = null
            tools[i].appendChild(faIconChild)
        }

        const sendDrawingtoWebsocket = async (data) => {
            await data&&socket.readyState!==0&&socket.send(JSON.stringify({
                shape: data.shape,
                message: data.message
            }))
            if(data.shape){
                var values = await lc.getSnapshot(['shapes']).shapes[0]&&LC.util.last(lc.getSnapshot(['shapes']).shapes).data
                var minX = await values&&(values.pointCoordinatePairs?(Math.min.apply(null, values.pointCoordinatePairs.map((x)=>x[0]))-values.pointSize/2):0);
                var minY = await values&&(values.pointCoordinatePairs?(Math.min.apply(null, values.pointCoordinatePairs.map((x)=>x[1]))-values.pointSize/2):0);
                await save({
                    drawing: lc.getSnapshot(['shapes']),
                    offset: 
                    [
                        minX, 
                        minY
                    ]
                })
            }
        }
        //under construction
        var pinchZoom = document.getElementsByClassName("lc-drawing with-gui")[0].addEventListener('touchstart', ()=>{
            return document.getElementsByClassName("lc-drawing with-gui")[0].addEventListener('touchmove', (data)=>{
                data.touches.length===2&&console.log((data.touches[0].clientY-data.touches[1].clientY)/(data.touches[0].clientX-data.touches[1].clientX))
                // sendDrawingtoWebsocket({
                //     message: (data.touches[0].clientY-data.touches[1].clientY)/(data.touches[0].clientX-data.touches[1].clientX)
                // })
                // return data.touches.length;
            })
        })
        lc.on('drawStart', ()=>{
            //save after 5 secconds if no further input
            // listening to drawing change event
            var unsubscribe = lc.on('drawingChange', ()=>{
                sendDrawingtoWebsocket({shape:{
                    className:LC.util.last(lc.getSnapshot(['shapes']).shapes).className,
                    color: tgtUser._id?"#c22929":"#006eff",
                    points:LC.util.last(lc.getSnapshot(['shapes']).shapes).data.pointCoordinatePairs, 
                }})
            })
            lc.on('drawEnd', ()=>unsubscribe())
        })
        lc.on('toolChange', ()=>{
            Object.getPrototypeOf(lc.tool).name === "Eraser"?
                lc.tool.strokeWidth = 20
                :
                lc.tool.strokeWidth = 2
        })
        lc.on('undo', ()=>{
            sendDrawingtoWebsocket({shape:{className:'undo'}})
        })
        //handle undo and redo temporarily, this needs to be update later
        lc.on('redo', ()=>{
            if(LC.util.last(lc.getSnapshot(['shapes']).shapes).className!=='Image'){
                sendDrawingtoWebsocket({shape:{
                    className:LC.util.last(lc.getSnapshot(['shapes']).shapes).className,
                    color: LC.util.last(lc.getSnapshot(['shapes']).shapes).data.pointColor,
                    points:LC.util.last(lc.getSnapshot(['shapes']).shapes).data.pointCoordinatePairs, 
                }})
            }else{
                sendDrawingtoWebsocket({shape: {
                    className:LC.util.last(lc.getSnapshot(['shapes']).shapes).className
                }})
            }
        })

        socket.onmessage = async (e) => {
            let data = JSON.parse(e.data).shape
            JSON.parse(e.data).message&&console.log(JSON.parse(e.data).message)
            // recieved broadcast data
            if(data){
                if(data.className==='LinePath'||data.className==='ErasedLinePath'){
                    lc.saveShape(LC.createShape(data.className, {
                        points: data.points.map(
                            points=>LC.createShape('Point', {x:points[0], y:points[1], size:data.className==='LinePath'?2:25, color:data.color})
                        )
                    }))
                }else if(data.className==='undo') {
                    lc.loadSnapshot({shapes: lc.getSnapshot(['shapes']).shapes.slice(0, -1)})
                }else if(data.className==='Image') {
                    var drawingImg = new Image();
                    drawingImg.src = drawingSnapshot.snapshot
                    lc.saveShape(LC.createShape('Image', {
                        x: drawingSnapshot.offset?drawingSnapshot.offset[0]:0, 
                        y: drawingSnapshot.offset?drawingSnapshot.offset[1]:0, 
                        image: drawingImg,
                        scale: 1
                    }))
                }
            }
        }
    };
    useEffect(()=>{
        if(currentResource._id) {
            setSocket(new WebSocket(websocket+currentResource._id+socketApiKey));
            DrawingAPI.loadDrawing(token, currentResource._id, tgtUser._id??myInfo._id)
            .then(response=>{
                if(!response.data.DrawingSnapshot){
                DrawingAPI.saveDrawing(token, currentResource._id, tgtUser._id??myInfo._id, [])
                .then(response=>{
                    setDrawingId(response.data)
                    setDrawingSnapshot([])
                })
                }else{
                    setDrawingSnapshot(response.data.DrawingSnapshot)
                    setDrawingId(response.data._id)
                }
            }).catch(err=>{
                console.log(err)
            })
        }
    },[currentResource, tgtUser])
        useEffect(()=>{
            if(currentResource.Type==='Workbook'){
                setAllowSolutions(currentResource.Solutions.length>0?currentResource.Solutions[0]._id:false)
                setShowBanner(currentResource.Category)
                setImgs(currentResource.Chapters[bookmark.Chapter].Navigations[bookmark.Section].Worksheets)
                currentResource.Chapters[bookmark.Chapter].Navigations[bookmark.Section].Worksheets.map(()=>setImg(item=>[...item, new Image]))

            }else if(currentResource.Type==='assignment'){
                setAllowSolutions(currentResource.Solution!=='false'?currentResource.Solution:false)
                setShowBanner(currentResource.Category);
                setImgs(currentResource.Worksheets)
                currentResource.Worksheets.map(()=>{
                    setImg(item=>[...item, new Image])
                })
            }
            currentResource.type==='uploads'&&UploadsAPI.uploadsShow(token, currentResource.id)
            .then(async response=>{
                await setImgs(response.data.Worksheets)
                await response.data.Worksheets.map(async ()=>{
                    await setImg(item=>[...item, new Image])
                })
            })
        },[bookmark, currentResource, tgtUser])
        useEffect(()=>{
            generateBackgroundWorksheet(()=>{
                var dynamicBanner = new Image;
                    dynamicBanner.src = srcGroup.banner&&showBanner==="Foundation"&&"https://d3i7bhlv78zipy.cloudfront.net/banners-upload/"+srcGroup.banner;
                var staticBanner = new Image;
                    staticBanner.src = showBanner==="Foundation"&&"/assets/static-banner.jpeg";
                const backgroundWorksheets = [];
                    imgs.sort().forEach((item, index) => {
                        img[index].src = assetsSrc+(currentResource.Type!=='uploads'?'vendors-upload/':'users-upload/')+(currentResource.Type==='assignment'?currentResource.Origin:currentResource._id)+'/'+item;
                        img[index].height = 2750;
                        img[index].width = 2126;
                        backgroundWorksheets.push(
                        LC.createShape('Image', {
                            x: 0, 
                            y: index*750, 
                            image: img[index],
                            scale: 0.26
                        }),
                        LC.createShape('Image', {
                            x: 30, 
                            y: index*750+40, 
                            image: staticBanner,
                            scale: 0.38
                        }),
                        LC.createShape('Image', {
                            x: 165, 
                            y: index*750+85.4, 
                            image: dynamicBanner,
                            scale: 0.0735
                        }),
                        )
                    })
                
                return backgroundWorksheets;
            })
        },[img, showBanner, currentResource, tgtUser])
    return (
        <div className="h-100 w-100">
            <div
                className="h-100 overflow-hidden d-flex justify-content-end"
            >
                {allowSolutions&&<RightSlide
                    showSolutionsSlide={showSolutionsSlide}
                    items={imgs.map(item=>allowSolutions+'/'+item)}
                    category={showBanner}
                />}
                {backgroundWorksheet.length>0&&drawingSnapshot&&
                    <LcContainer/>
                }
            </div>
        </div>
    )
}
