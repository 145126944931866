import axios from "axios";
import { api } from "./config";

export default class API{
    static async groupIndex (token, groupId) {
        return axios.get(api+'api/groups'+(groupId&&'?groupId='+groupId), {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }
    static async groupsShow(token, groupId) {
        return axios.get(api+'api/groups/'+groupId, {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })

    }
    static async addGroup(token, data) {
        return axios.post(api+'api/groups', data, {
            'headers': {
                'Authorization': 'Bearer '+token,
                "Content-Type": "multipart/form-data",
            }
        })
    }
    static async updateGroup(token, data) {
        return axios.put(api+'api/groups/'+data._id, data, {
            'headers':{
                'Authorization':'Bearer '+token
            }
        })
    }
    
    static async removeGroup(token, groupId) {
        return axios.delete(api+'api/groups/'+groupId, {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    } 
}