import { Button } from "react-bootstrap"
import Worksheet from "../../../../components/Worksheet";
import { localDeployment } from "../../../../../API/config";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

export default function BottomTray(props) {
    const {
        worksheets, 
        setWorksheets,
        setSelectAllWorksheets,
        setHideTray,
        removeWorksheets,
        setTrayHeight,
        setAssignmentModel,
        trayHeight,
        hideTray,
        print
    } = props;
    useEffect(()=>{
        setHideTray(false);
    },[])
    useEffect(()=>{
        !hideTray?
        setTrayHeight('15.5rem')
        :
        setTrayHeight(0)
    },[hideTray])
    const screen = useSelector(state=>state.screen)
    const bookmark = useSelector(state=>state.bookmark)
    const srcGroup = useSelector(state=>state.srcGroup)
    const dispatch = useDispatch();
    const currentResource = useSelector(state=>state.currentResource)
    const setResourceDetails = (item) => {
        dispatch({type: 'SET_CURRENT_RESOURCE', payload: item})
    }
    return(
        <div
            className="fixed-bottom"
            style={{height:trayHeight, zIndex:'100000'}}
        >
            <div 
                className="position-absolute"
                style={{top:'-54px', left:0}}
            >
                <div className="col-auto row p-2 bg-dark rounded-top">
                <Button
                    variant="danger"
                    className="me-1 col-auto"
                    onClick={()=>{
                        setHideTray(true)
                        setTrayHeight(0)
                        setWorksheets([])
                        setSelectAllWorksheets(true)
                    }}
                >
                    Clear
                </Button>
                <Button
                    variant="secondary"
                    className="mx-1 col-auto"
                    onClick={()=>setHideTray(!hideTray)}    
                >
                    {hideTray?'Show tray':'Hide tray'}
                </Button>
                {bookmark&&screen.indexOf('vendor')<0?
                !localDeployment&&<Button
                    variant="light"
                    className="ms-1 col-auto"
                    onClick={()=>{
                        setAssignmentModel(true)
                    }}
                >
                    Assign
                </Button>
                :
                <Button
                    variant="light"
                    className="ms-1 col-auto"
                    onClick={async ()=>{
                        const prevState = currentResource
                        await prevState.Chapters.forEach((chapter, cindex)=>
                            chapter.Navigations?
                            chapter.Navigations.forEach((section, sindex)=>
                                prevState.Chapters[cindex].Navigations[sindex].Worksheets = 
                                (section.Worksheets&&section.Worksheets.some(worksheet=>worksheets.indexOf(worksheet)<0))?
                                prevState.Chapters[cindex].Navigations[sindex].Worksheets.filter(item=>worksheets.indexOf(item)<0)
                                :
                                [])
                            :
                            prevState.Chapters[cindex] = {
                                ...chapter,
                                Navigations: []
                            }
                        )
                        await prevState.Chapters[bookmark.Chapter].Navigations[bookmark.Section].Worksheets.push(...worksheets);
                        await setHideTray(true)
                        await setTrayHeight(0)
                        await setWorksheets([])
                        await setSelectAllWorksheets(true)
                        await setResourceDetails({...prevState})
                    }}
                >
                    Move to {bookmark.label}
                </Button>}
                {print&&<Button
                    variant="light"
                    className="ms-1 col-auto"
                    onClick={async ()=>{
                        await dispatch({type: "SHOW_PRELOAD", payload: true})
                        await print(worksheets, currentResource._id, currentResource.Category)
                        await dispatch({type: "SHOW_PRELOAD", payload: false})
                    }}
                >
                    Print
                </Button>}
                </div>
            </div>
            <div
                className="overflow-x-scroll"
                style={{
                    display:hideTray?'none':'flex',
                    background:'rgba(0,0,0,0.4)'
                }}
            >
                {worksheets.map((items, index)=>
                    <div 
                        key={index} 
                        className="py-2"
                        style={{width:170, height:220}}
                        onClick={()=>{
                            removeWorksheets(items)
                        }}    
                    >
                        <Worksheet
                            category={currentResource.Category}
                            className='w-100' 
                            src={currentResource.type==='Uploads'?'users-upload/':'vendors-upload/'+currentResource._id+"/"+items+"?w=150"}
                            bannerUploaded={srcGroup.banner}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}