import { Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";


export default function UpdateObjectModel (props) {
    const {
        model,
        submit,
        children,
        reload,
    } = props
    const dispatch= useDispatch();
    const submitData = {
        "resource":useSelector(state=>state.currentResource),
        "group":useSelector(state=>state.tgtGroup),
        "user":useSelector(state=>state.tgtUser),
        "self":useSelector(state=>state.myInfo)
    }[model]
    const allowSubmit = useSelector(state=>state.allowSubmit)
    return(
        <>
        <div 
            className="text-white text-start p-3 bg-secondary col order-1"
        >
            <p className="m-0">Edit {model==='self'?'personal info':model}</p>
        </div>
        <div className="col-12 order-3">
            <div className="py-2">
                {children}
            </div>
            <div className="d-flex justify-content-end">
                <Button
                    disabled={!allowSubmit}
                    onClick={async ()=>{
                        await dispatch({ type: 'SHOW_PRELOAD', payload: true })
                        await submit(submitData).then(async ()=>{
                            await reload()
                            await dispatch({ type: 'SHOW_LEFT_SLIDE', payload: false })
                        })
                        await dispatch({ type: 'SHOW_PRELOAD', payload: false })
                    }}
                >
                    Update
                </Button>

            </div>
        </div>
        </>
    )
}
