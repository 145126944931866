import { useEffect, useState } from "react"
import { FloatingLabel, Form, Button } from "react-bootstrap"
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom"

import API from "../../../API/accounts";

export default function Login () {
const dispatch = useDispatch();
const navigate = useNavigate();
const location = useLocation();

const [username, setUsername] = useState();
const [password, setPassword] = useState();
const myInfo = useSelector(state=>state.myInfo)
const authenticate = async () => {
    await dispatch({type:'SHOW_PRELOAD', payload: true});
    (username&&password)?
    await API.authenticate({username: username.toLowerCase(), password: password})
    .then(async token=>{
        await dispatch({ type: 'SET_TOKEN', payload: token.data });
        await API.getAccount(token.data)
        .then(async response=>{
            await dispatch({ type: 'SET_MY_INFO', payload: response.data.myInfo });
            await dispatch({ type: 'SET_MY_GROUP', payload: response.data.group});
            await dispatch({ type: 'SET_SRC_GROUP', payload: response.data.group});
            await dispatch({ type: 'SET_ROLE', payload: response.data.myInfo.admin===response.data.group._id?'admin':'user'});
            if(location.state){
                await navigate(location.state.back, {replace: true})
            }else{
                await navigate('resources', {replace: true})
                await dispatch({type:"SET_PORTAL", payload:'EpenApp'})
            }
        })
    })
    .catch(err=>{
        console.log(err)
        dispatch({ type: 'SET_NOTIFICATION', payload: {
            show: true,
            code: err.response.data.code,
            message: err.response.data.error
        }})}
    )
    :
    dispatch({ type: 'SET_NOTIFICATION', payload: {
        show: true,
        code: 204,
        message: 'Required fields need to be filled'
    }})
    await dispatch({type:'SHOW_PRELOAD', payload: false});
}

useEffect(()=>{
    !myInfo&&dispatch({type:'SET_SCREEN', payload: 'login'})
},[myInfo])

return(
<>
    <h1 className="mb-3 text-black">Welcome</h1>
    <div className="mb-3 px-3">
        <FloatingLabel 
            className="secondary-font text-secondary"
            label="Username or Email"
        >
            <Form.Control
            placeholder="Username"
            className="border-0 border-bottom rounded-0 bg-none mb-3"
            onChange={e=>setUsername(e.target.value)}
            onKeyDown={e=>{
                if(e.keyCode === 13){
                    authenticate()
                }
            }}
            />
        </FloatingLabel>
        <FloatingLabel
            className="secondary-font text-secondary"
            label="Password"
        >
            <Form.Control
            placeholder="Password"
            className="border-0 border-bottom rounded-0 bg-none"
            type="password"
            onChange={e=>setPassword(e.target.value)}
            onKeyDown={e=>{
                if(e.keyCode === 13){
                    authenticate()
                }
            }}
            />
        </FloatingLabel>
    {/* <div className="text-end">
        <Link>Forgot password</Link>
    </div> */}
    <div className="row mt-3">
        <Button 
            className="my-2 bg-304d73 border-0"
            onClick={()=>authenticate()}
        >Sign in</Button>
        <p>New to EpenClass?<Link
            className="ms-2"
            onClick={()=>dispatch({type:"SET_SCREEN", payload:'register'})}
        >Sign up</Link></p>
    </div>
    </div>
</>
    )
}