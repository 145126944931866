import { useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { Button } from "react-bootstrap";

import Worksheet from "./";
import LeftSlideToggleIcon from "../leftSlideToggleIcon";
import DeleteObjectModel from "../Models/deleteObjectModel";
import ResourceDetails from "../../screen/Resources/resourceDetails";
import Searchbar from "../SubNav/searchbar";
import RowContainer from "../Table/rowContainer";
import { loadUser } from "../../screen/Users/usersAction";
import { isMobile } from "react-device-detect";
import UpdateObjectModel from "../Models/updateObjectModel";

export default function Container(props) {
    const {
        type,
        item,
        actions,
        loadResources,
        showPricing
    } = props
    
    const dispatch = useDispatch();
    const [opacity, setOpactiy] = useState(0);
    const token = useSelector(state=>state.token);
    const tgtUser = useSelector(state=>state.tgtUser);
    const role = useSelector(state=>state.role);
    const srcGroup = useSelector(state=>state.srcGroup);
    return(
    <div
        className="position-relative mx-2 mb-3 bg-dark"
        onMouseEnter={()=>setOpactiy(1)}
        onMouseLeave={()=>setOpactiy(0)}
        style={{
            height: 220, 
            width: 170,
        }}
    >
        <div
            className='w-100 h-100 p-0 drop-shadow overflow-hidden'
        >
            <div 
                className='py-2 row prime-font text-start position-absolute overflow-auto'
                style={{opacity: isMobile?1:`${opacity}`, zIndex:1000, height:!(isMobile&&type!=="assignment")?'100%':'auto', bottom:0, background: "rgba(0,0,0,0."+(!(isMobile&&type!=="assignment")?6:8)+")"}}
            >
                <div 
                    className="text-white hide-scrollbar"
                >
                    {!(isMobile&&type!=="assignment")?
                    <>
                        <p className='m-0 prime-font'>{item.Title}</p>
                        {item.Level&&item.Level !== '13'? <p className='m-0'>Grade: {item.Level}</p>:''}
                        {item.Subject&&<p className='m-0'>Subject: {item.Subject}</p>}
                    </>
                    :
                    <p className="mb-1 prime-font">{item.Subject} - {item.Level}</p>
                    }
                </div>
                <div className="row m-0 p-0 align-items-end">
                <div>
                {actions.map((action, index)=>
                (type!=="assignment"||tgtUser._id||role==='user')&&action.verb==="SHOW"?
                <Button
                    key={index}
                    variant="outline-light" 
                    className="secondary-font w-100"
                    onClick={()=>{
                        action.execute(item).then(async res=>{
                            await dispatch({ type: 'SET_CURRENT_RESOURCE', payload: res.data})
                            await res.data.Type==="Workbook"&&dispatch({ type: 'SET_BOOKMARK', payload: {
                                'Chapter': 0,
                                'Section': 0,
                                'label': res.data.Chapters[0].Navigations[0].Label
                            }});
                        })
                    }}
                >
                    {action.name}
                </Button>
                :
                <div 
                    key={index} 
                    className="mt-1"
                >
                    <LeftSlideToggleIcon
                        variant={{
                            'DELETE':'warning',
                        }[action.verb]
                        ||
                        'light'
                        }
                        className="w-100"
                        outline
                        disabled={action.disabled&&action.disabled(item)}
                        label={typeof action.name==="string"?action.name:action.name(item)}
                        onClick={
                        ({
                            'SHOW':()=>action.execute(item).then(async res=>
                                await dispatch({ type: 'SET_CURRENT_RESOURCE', payload: res.data})
                            ),
                            'CUSTOM':()=>action.execute(item)
                            }[action.verb]
                            ||
                            (()=>dispatch({ type: 'SET_CURRENT_RESOURCE', payload: item}))
                        )}
                        slideInContent={
                        {
                            'SHOW':
                            ()=><>
                                <div className="bg-secondary p-3 text-start col order-1">
                                    <p className="m-0">Switch folder</p>
                                </div>
                                <div className="col-12 order-3 p-0">
                                <div className="p-2">
                                <Searchbar
                                    type={'users'}
                                />
                                </div>
                                <RowContainer
                                    target={'user'}
                                    tableEntryTemplates={[
                                        {
                                            'label': '',
                                            'key': '',
                                            'col':'auto',
                                            'actions':[]
                                        },
                                        {
                                            'label': 'Name',
                                            'key': 'fullname',
                                            'col':'',
                                            'actions':[]
                                        },
                                        {
                                            'label': 'Actions',
                                            'col':'auto',
                                            'key': 'actions',
                                            'actions':[]
                                        },
                                    ]}
                                    extractFilter={(e)=>e.filter(item=>item.name==='users')[0].value}
                                    onLoad={(setEntries, filters)=>loadUser(token, srcGroup._id, filters, setEntries)}
                                />
                                </div>
                            </>,
                            'PUT':
                            ()=><UpdateObjectModel
                                model='resource'
                                submit={action.execute}
                                reload={loadResources}
                            >
                                <ResourceDetails/>
                            </UpdateObjectModel>,
                            'DELETE':
                            ()=><DeleteObjectModel
                                model='resource'
                                submit={action.execute}
                                reload={loadResources}
                            />,
                        }[action.verb]}
                    />
                </div>
                )}
                </div>
            </div>
            </div>
                <Worksheet 
                    src={("vendors-upload/")+(item.Origin??item._id)+"/"+(item.Cover??item.Worksheets[0])+'?w=150'}
                    category={!item.Cover&&item.Category}
                    bannerUploaded={!item.Cover&&srcGroup.banner}
                    className='w-100'
                />
        </div>
        {showPricing&&
            <p className="m-0 bg-secondary text-white rounded-3 mt-4 py-1">${item[showPricing]}</p>
        }
        </div>
    )
}