import { useEffect, useState } from "react";
import { Tabs, Tab, FloatingLabel, Form, Button } from "react-bootstrap";
import UserDetails from "./userDetails"
import { useDispatch, useSelector } from "react-redux";
import API from "../../../API/users";

export default function UserDetailsContainer () {

const token = useSelector(state=>state.token)
const tgtUser = useSelector(state=>state.tgtUser)
const srcGroup = useSelector(state=>state.srcGroup)
const keys = ['Create', 'Invite'];
const [key, setKey] = useState(keys[0]);
const [username, setUsername] = useState([]);
const [isValidUser, setIsValidUser] = useState([]);
const dispatch = useDispatch();
const validate = (username) => {
    API.addUser(token, {
        username:username.toLowerCase(),
        type:'invitation'
    }).then(res=>{
        dispatch({type: 'SET_ALLOW_SUBMIT', payload: true});
        dispatch({type: 'SET_TGT_USER', payload:{ 
            type:'invitation',
            ...tgtUser,
            groups: [{_id:srcGroup._id, name:srcGroup.name, role:'User'}],
            ...res.data.user,
        }});
        setIsValidUser(res.data.error)
    }).catch(err=>{
        setIsValidUser(err.response.data.error)
    })
}
useEffect(()=>{
    key===keys[0]&&dispatch({type:"RESET_USER"})
    setIsValidUser([])
},[key])
return (
    <Tabs
        activeKey={key}
        onSelect={(k) => setKey(k)}
        variant='pills'
        className="secondary-font my-2 p-1 rounded-1"
        style={{background:"rgba(0,0,0,0.1)"}}
    >
        {keys.map((item, index)=>
        <Tab eventKey={item} key={index} title={item}>
            {key==='Create'?
                !tgtUser._id&&<UserDetails/>
                :
                <div className="px-2">
                <FloatingLabel
                    label={'Username or email address'}
                    className="secondary-font text-dark mb-3 col d-flex"
                >
                    <Form.Control
                        type="text"
                        placeholder="Username or email address"
                        className="border-0 border-bottom rounded-0"
                        onChange={(e)=>setUsername(e.target.value)}
                    />
                    <Button 
                        variant='outline-secondary'
                        disabled={!username||username.length<=0}
                        className="mt-3"
                        onClick={()=>validate(username)}
                    >
                        Validate
                    </Button>
                </FloatingLabel>
                <p className={(isValidUser.indexOf("available")>=0?"text-success":"text-danger")+" m-0 text-start"}>{isValidUser}</p>
                </div>
            }
        </Tab>
        )}
    </Tabs>
)
}