import { Button} from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import { localDeployment } from "../../../API/config";

export default function DeleteObjectModel (props) {
    const {
        submit,
        model,
        reload,
    } = props
    const dispatch = useDispatch();
    const screen = useSelector(state=>state.screen)
    const submitData = {
        "resource":useSelector(state=>state.currentResource),
        "group":useSelector(state=>state.tgtGroup),
        "user":useSelector(state=>state.tgtUser)
    }[model]
    return(
    <>
        <div 
            className="text-white text-start p-3 bg-secondary col order-1"
        >
            {(!localDeployment&&screen.indexOf('vendor')>=0)?
                <p className="m-0">Vendor resource can only be deleted in HQ server</p>
                :
                <p className="m-0">Are you sure you want to delete? This cannot be undone</p>}
        </div>
        <div className="d-flex justify-content-end px-3 my-2 col-12 order-3">
            <Button
                className="me-1"
                disabled={(!localDeployment&&screen.indexOf('vendor')>=0)}
                onClick={async ()=>
                        await submit(submitData).then(async ()=>{
                        await reload&&reload()
                        await dispatch({ type: 'SHOW_LEFT_SLIDE', payload: false })
                    })
                }
            >
                    Confirm
            </Button>
            <Button
                variant="danger"
                onClick={()=>
                    dispatch({ type: 'SHOW_LEFT_SLIDE', payload: false })
                }
            >
                    Cancel
            </Button>
        </div>
    </>
    )
}