import Login from "./login";
import Register from "./register";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Index () {
    const screen = useSelector(state=>state.screen)
    const myInfo = useSelector(state=>state.myInfo)
    const navigate = useNavigate()
    useEffect(()=>{
        myInfo&&navigate('./resources', { replace: true })
    }, [myInfo])
    
    return(
<div className="bg-custom ePen-theme-secondary vh-100 w-100 fixed-top p-0" style={{zIndex:1000}}>
    <div
        className="shadow-overlay"
        style={{backgroundColor:'rgba(0,0,0,0.3)'}}
    >
    <div className="row justify-content-center align-items-center h-100">
        <div style={{width: 450}} className='bg-white p-4 drop-shadow rounded text-dark'>
            {screen.indexOf('register')>=0?
                <Register/>
                :
                <Login/>
            }
            </div>
        </div>
    </div>
</div>
    )
}