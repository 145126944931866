import { useEffect, useState } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../components/Table";
import RowContainer from "../../components/Table/rowContainer";
import { addUser, updateUser, removeUser, loadUser } from "./usersAction";
import { faPlus, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import UserDetailsContainer from "./userDetailsContainer";
import UserDetails from "./userDetails";

export default function Users () {

    const dispatch = useDispatch();
    const showLeftSlide = useSelector(state=>state.showLeftSlide)
    const token = useSelector(state=>state.token)
    const srcGroup = useSelector(state=>state.srcGroup)
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(()=>{
        dispatch({ type: 'SET_SCREEN', payload: 'users'})
        dispatch({ type: 'RESET_UPDATABLE'})
    },[showLeftSlide])
    
    const tableEntryTemplates = [
        {
            'label': '',
            'key': '',
            'col':'auto',
            'actions':[]
        },
        {
            'label': 'Name',
            'key': 'fullname',
            'col':'',
            'actions':[]
        },
        {
            'label': 'Account',
            'key': 'username',
            'col':'',
            'actions':[]
        },
        {
            'label':"Roles",
            'key':'conditionalComponent',
            'col':'',
            conditionalComponent: ({data})=>
            <>
                {data.groups.map((group, index)=><p className="text-center m-0" key={index}>{group.role} of {group.name}</p>)}
            </>,
            'actions':[]
        },
        {
            'label': 'Actions',
            'key': 'actions',
            'col':'2',
            'actions':[
                {
                name:'Edit user',
                verb:'PUT',
                icon: faPencil,
                objectDetails: <UserDetails/>,
                execute: async (data)=>{
                    await updateUser(token, data).then(() =>
                        dispatch({ type: 'SET_NOTIFICATION', payload: {
                            show: true,
                            code: 200,
                            message: "Account "+data.username+' updated.'
                        }})
                    )
                }},
                {
                name:'Remove',
                verb:'DELETE',
                icon: faTrash,
                execute: async (data)=>{
                    await removeUser(token, data).then(() =>
                        dispatch({ type: 'SET_NOTIFICATION', payload: {
                            show: true,
                            code: 200,
                            message: "Account "+data.username+' removed.'
                        }})
                    )
                }},
            ]

        },
    ]

    const tableMenuActions = [
        {
            name:'New user',
            verb:'ADD',
            icon: faPlus,
            objectDetails: <UserDetailsContainer/>,
            execute: async (data)=>{
                await addUser(token, data).then(() =>
                    dispatch({ type: 'SET_NOTIFICATION', payload: {
                        show: true,
                        code: 200,
                        message: "Account "+data.username+' added.'
                    }})
                ).catch(err=>{
                    dispatch({ type: 'SET_NOTIFICATION', payload: {
                        show: true,
                        code: err.response.status,
                        message: err.response.data.error
                    }})
                })
        }},
    ]

    return (
    <div className="h-100 d-flex align-items-center justify-content-center">
        <Table
            tableEntryTemplates={tableEntryTemplates}
            tableMenuActions={tableMenuActions}
            className="drop-shadow"
            target='user'
            setIsSubmitted={setIsSubmitted}
        >
            <RowContainer
                target='user'
                tableEntryTemplates={tableEntryTemplates}
                extractFilter={(e)=>e.filter(item=>item.name==='users')[0].value}
                isSubmitted={isSubmitted}
                setIsSubmitted={setIsSubmitted}
                onLoad={(setEntries, filters)=>loadUser(token, srcGroup._id, filters, setEntries)}
            />  
        </Table>
    </div>
    )
}