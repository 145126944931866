import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";


export default function LazyLoadList (props) {
    const{
        pageLoad,
        groupEntries,
        setGroupEntries,
        tableEntryTemplates,
        entries,
        isChecked,
        setEntries,
        className,
        style,
        entriesIndex,
        groupEntriesIndex,
        onEntryCheck,
        extractFilter
    } = props
    const dispatch = useDispatch();
    const token = useSelector(state=>state.token)
    const srcGroup = useSelector(state=>state.srcGroup)
    const myGroup = useSelector(state=>state.myGroup)
    const tgtGroup = useSelector(state=>state.tgtGroup)
    const globalFilter = useSelector(state=>state.filters)
    const filters = extractFilter(globalFilter);
    const loadEntries = async () => {
        await dispatch({type: 'SHOW_PRELOAD', payload: true});
        if(entriesIndex){
            entriesIndex(token, filters, pageLoad)
            .then(response=>
                pageLoad===1?
                    setEntries(response.data)
                    :
                    setEntries([...entries, ...response.data])
            )
            .catch(err=>{
                dispatch({ type: 'SET_NOTIFICATION', payload: {
                    show: true,
                    code: 300,
                    message: 'Failed to load personal resources.'
                }})
                console.log(err)
            });
        }
        if(groupEntriesIndex){
            groupEntriesIndex(token, srcGroup._id, filters, pageLoad)
            .then(response=>
                pageLoad===1?
                    setGroupEntries(response.data)
                    :
                    setGroupEntries([...groupEntries,...response.data])
            )
            .catch(err=>{
                dispatch({ type: 'SET_NOTIFICATION', payload: {
                    show: true,
                    code: 500,
                    message: 'Failed to load group resources.'
                }})
                    console.log(err)
            });
        }
        await dispatch({type: 'SHOW_PRELOAD', payload: false});
    }
    useEffect(()=>{
        !(filters.input&&filters.input.length!==0)&&loadEntries()
    },[srcGroup, pageLoad, filters])

    return (
        ([...entries, ...groupEntries].length>0)?
        <div 
            className={className}
            style={style}
        >
            {[...entries, ...groupEntries]
            .filter(
            item=>filters.input!==''?
                item.Title.toLowerCase().indexOf(filters.input)>=0
                ||
                item.fullname.toLowerCase().indexOf(filters.input)>=0
            :
            item
            ).map((item, index)=>
            <div 
                className="w-100 row px-3 py-2 align-items-center"
                key={index}
            >
                {tableEntryTemplates.map((col, findex)=>
                    ({
                    '':
                    <div 
                        className={"col-"+col.col}
                        key={findex}
                    >
                        <Form.Check
                            type='checkbox'
                            checked={isChecked(item._id)??false}
                            disabled={tgtGroup._id===myGroup._id}
                            onChange={()=>onEntryCheck(item._id)}
                        />
                    </div>,
                    'conditionalComponent':
                    <div
                        className={"secondary-font col-"+col.col}
                        key={findex}
                    >
                        {col.conditionalComponent&&<col.conditionalComponent data={item}/>}
                    </div>
                    }[col.key])
                    ||
                    <div 
                        className={"secondary-font text-start "+(col.col?"col-"+col.col:"col")+(findex!==1?" text-center":"")}
                        key={findex}
                    >
                        {item[col.key]}
                    </div>
                )}
            </div>
            )}
        </div>
        :
        <div className="text-center" style={{height: '50vh'}}>
            <h3>No item found</h3>
        </div>
    );
}