import { localDeployment } from "../../../../API/config";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Nav, Row, Tab } from "react-bootstrap";

import Banner from "./banner";
import Subscription from "./subscription";
import UserDetails from "../../Users/userDetails";
import { useEffect } from "react";
import API from "../../../../API/users";

export default function Index () {
    const dispatch = useDispatch();
    const token = useSelector(state=>state.token)
    const role = useSelector(state=>state.role)
    const myInfo = useSelector(state=>state.myInfo)
    const submit = (target) => {
        console.log(target)
    }
    const keys = [
        {
            title: "General",
            content: 
            <div className="p-3">    
                <UserDetails/>
                <div>
                    <Button onClick={submit('user')}>Apply changes</Button>
                </div>
            </div>
        },
        {
            title: "Banner",
            restriction: role!=="admin",
            content: <Banner/>
        },
        {
            title: "Subscription",
            restriction: (localDeployment||myInfo.admin==='1'),
            content: <Subscription/>
        },
        {
            title: "Payment",
            content: <div></div>
        }
    ]
    useEffect(()=>{
        API.userShow(token, myInfo._id).then(res=>{
            dispatch({type:'SET_TGT_USER', payload: res.data})
        })
    },[])
    return (
    <div 
        className="row align-items-center justify-content-center w-100 h-100 p-0"
    >
        <div
            className="bg-white drop-shadow rounded row p-0 overflow-hidden"
            style={{height:'90%', width:'80%', zIndex:100}}
        >
        <Tab.Container
            defaultActiveKey={keys[0].title}
            className="h-100"
        >
        <Row
            className="p-0 h-100"
        >
            <Col className="col-auto py-2 bg-secondary" >
            <Nav variant="pills" className="flex-column">
                {keys.map((key, index)=>
                !key.restriction&&<Nav.Item key={index}>
                    <Nav.Link eventKey={key.title} className="py-1 text-start">{key.title}</Nav.Link>
                </Nav.Item>
                )}
            </Nav>
            </Col>
            <Col className="h-100 p-0">
            <Tab.Content className="h-100 overflow-hidden">
                {keys.map((key, index)=>!key.restriction&&<Tab.Pane key={index} className="h-100" eventKey={key.title}>{key.content}</Tab.Pane>)}
            </Tab.Content>
            </Col>
        </Row>
        </Tab.Container>
        </div>
    </div>
    )
}